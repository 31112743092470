import { useCallback, useEffect, useRef, useState } from "react";

import { verifyPartnerInvite, verifyUserEmail } from "api";
import {
  IconBronze,
  IconDiamond,
  IconGold,
  IconPlatinum,
  IconSilver,
} from "assets";
import { encode as base64_encode } from "base-64";
import { LoadingScreenSpinner } from "components";
import { renderToStaticMarkup } from "react-dom/server";
import { HiOfficeBuilding } from "react-icons/hi";
import { useNavigate, useParams } from "react-router-dom";
import { ABNService, sanitizeInputWithSpace } from "services";
import { ROUTES } from "variables";

import { T2InviteFormSearchABN } from "./t2-invite-search-abn";
import { T2ConfirmLoginWithInvite } from "./t2-confirm-login-with-invite";

const INITIAL_REGISTER_STATE = {
  abn: "",
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  cPassword: "",
  code: "",
  accept: false,
};

const LEVEL_ICONS = [
  null,
  IconBronze,
  IconSilver,
  IconGold,
  IconPlatinum,
  IconDiamond,
];
const LEVELS = [null, "BRONZE", "SILVER", "GOLD", "PLATINUM", "DIAMOND"];

export function T2Invite() {
  const params = useParams();
  const navigate = useNavigate();

  const [registerState, setRegisterState] = useState(INITIAL_REGISTER_STATE);
  const [logo, setLogo] = useState();
  const [logoL, setLogoL] = useState();
  const [partnerOrg, setPartnerOrg] = useState();
  const [abnSearchRes, setAbnSearchRes] = useState();
  const [abnSearchErr, setAbnSearchErr] = useState();
  const [abnAvailable, setAbnAvailable] = useState(false);
  const [abnRegistered, setAbnRegistered] = useState(false);
  const [orgSearchRes, setorgSearchRes] = useState();
  const [step, setStep] = useState("default"); // default, login_with_invite
  const [inviteVerifyEmail, setInviteVerifyEmail] = useState();

  const pwdStrengthRef = useRef(null);

  const handleInput = (event) => {
    setAbnSearchErr(null);

    if (event.target.name === "password") {
      pwdStrengthRef?.current?.check(event.target.value);
    }
    setRegisterState({
      ...registerState,
      [event.target.name]: event.target.value,
    });
  };

  const searchABN = async (ABN = registerState?.abn) => {
    try {
      setRegisterState({
        ...registerState,
        abn: sanitizeInputWithSpace(ABN),
      });

      const lookupResult = await ABNService.lookup({ abn: ABN });
      if (!lookupResult.result) {
        setAbnSearchRes(undefined);
        setAbnSearchErr(lookupResult.error);
        return undefined;
      }

      const existedOrganisation = lookupResult?.organisation;
      let result = lookupResult.result;
      let customerLevel = existedOrganisation?.customer?.level || 0;

      if (!!existedOrganisation?.customer) {
        setorgSearchRes({
          ...existedOrganisation,
          certifiedStatus:
            // if customer is not certified or less than partner's offered level, -1,
            // if equal, 0, if higher, 1
            !existedOrganisation || customerLevel < partnerOrg?.level
              ? -1
              : customerLevel === partnerOrg?.level
                ? 0
                : 1,
        });
      }

      if (!existedOrganisation) {
        setAbnAvailable(true);
        setAbnRegistered(false);
      } else if (existedOrganisation.confirmed) {
        setAbnSearchErr(result);
        setAbnRegistered(true);
      } else {
        setAbnAvailable(true);
        setAbnRegistered(false);
      }

      setAbnSearchRes(result);
      return result;
    } catch (error) {
      console.error(`ERROR: LOOKUP ABN: ${JSON.stringify(error)}`);
    }
  };

  const resetSearch = () => {
    setAbnSearchRes(undefined);
    setInviteVerifyEmail(null);
    setStep("default");
    setAbnRegistered(false);
    setAbnSearchErr(false);
    setRegisterState(INITIAL_REGISTER_STATE);
  };

  const validateToClaim = (event) => {
    event?.preventDefault();

    window.location.href = `${ROUTES.ROOT}?c=${encodeURIComponent(
      base64_encode(
        JSON.stringify({
          claim: true,
          ABN: registerState?.abn,
          partnerABN: partnerOrg?.ABN,
          claimCode: partnerOrg?.claimCode,
        })
      )
    )}`;
  };

  const setup = useCallback(async () => {
    const token = params["token"];
    // reset t2 invite accept by login
    try {
      sessionStorage.removeItem("ACCEPT_PARTNER_INVITE_BY_LOGIN");
    } catch (e) {}
    if (!token) {
      navigate(ROUTES[404]);
    } else {
      // verify token
      const verify = await verifyPartnerInvite({ code: token });

      if (!verify?.id) {
        navigate(ROUTES[404]);
      } else {
        if (!verify?.logo) {
          setLogo(null);
          setLogoL(null);
        } else if (!verify?.logo?.includes("favicon")) {
          setLogoL(verify?.logo);
        } else {
          setLogo(verify?.logo);
        }

        setPartnerOrg((partnerOrg) => ({
          ...partnerOrg,
          ABN: verify?.ABN,
          name: verify?.partnerName,
          // logo: verify?.logo,
          // logoL: CoreCyberLogo,
          claimCode: verify?.code,
          level: verify?.level,
          pageTitle: verify?.pageTitle,
          description: verify?.description,
        }));
      }
    }
  }, [params]);

  useEffect(() => {
    setup();
  }, [setup]);

  useEffect(() => {
    const getPartnerLogoHTML = () => {
      return (
        <>
          <div className="flex flex-row items-center justify-center gap-3">
            {!!logoL ? (
              <img
                src={`${logoL}`}
                alt={`${partnerOrg?.name} Logo`}
                className="h-10"
              />
            ) : !!logo ? (
              <>
                <img
                  src={`${logo}`}
                  alt={`${partnerOrg?.name} Logo`}
                  className="h-10 w-10"
                />

                <span className="text-xl font-semibold text-black">
                  {partnerOrg?.name}
                </span>
              </>
            ) : (
              <>
                <HiOfficeBuilding className="text-gray h-[30px] w-[30px]" />

                <span className="text-xl font-semibold text-black">
                  {partnerOrg?.name}
                </span>
              </>
            )}
          </div>
        </>
      );
    };

    // change logo in navbar to partner
    if (!!partnerOrg) {
      // Render JSX into raw HTML
      const html = renderToStaticMarkup(getPartnerLogoHTML());

      // Set inner HTML of logo to change image
      document.getElementById("cybercert-navbar-logo").innerHTML = html;
    }
  }, [logo, logoL, partnerOrg]);

  async function loginWithInvite() {
    setStep("login_with_invite");
  }

  async function confirmLoginWithInvite() {
    let verifyEmailABN = await verifyUserEmail({
      email: inviteVerifyEmail,
      ABN: abnSearchRes?.ABN,
    });
    if (!!verifyEmailABN?.user) {
      if (abnRegistered && orgSearchRes?.certifiedStatus === -1) {
        // accept partner invite
        sessionStorage.setItem(
          "ACCEPT_PARTNER_INVITE_BY_LOGIN",
          JSON.stringify({
            code: partnerOrg?.claimCode,
            ABN: registerState?.abn,
            status: "await_signin",
          })
        );
      } else if (!!sessionStorage.getItem("ACCEPT_PARTNER_INVITE_BY_LOGIN")) {
        sessionStorage.removeItem("ACCEPT_PARTNER_INVITE_BY_LOGIN");
      }
      navigate(`${ROUTES.LOGIN}?email=${inviteVerifyEmail}`);
    } else {
      return {
        error: {
          message: `The email you entered does not associate with the ABN ${abnSearchRes?.ABN}.`,
        },
      };
    }
  }

  return (
    <>
      {!partnerOrg && <LoadingScreenSpinner />}

      <div className="content-body">
        <div className="form-container flex flex-col gap-8 tablet:flex-row">
          <div className="flex w-full flex-col justify-between gap-8 tablet:gap-0">
            <div className="flex flex-col gap-8">
              <div className="title text-[38px] font-semibold text-black">
                {partnerOrg?.pageTitle || "Accept Invitation"}
              </div>

              <div className="flex flex-row items-center gap-3 font-semibold text-black">
                <img
                  src={LEVEL_ICONS[partnerOrg?.level]}
                  alt={`SMB1001 ${LEVELS[partnerOrg?.level]} certification`}
                  width={32}
                  height={32}
                />
                <span className="text-xl font-semibold text-black">
                  {LEVELS[partnerOrg?.level]}
                </span>
              </div>
              {/* please update icon depending on what certs are available for claim */}

              <p className="flex w-full text-sm leading-normal text-gray-600 tablet:w-[70%]">
                {partnerOrg?.description || (
                  <>
                    Your organisation has been invited to claim a{" "}
                    {LEVELS[partnerOrg?.level]} certification subscription.
                  </>
                )}
              </p>

              {step === "login_with_invite" && (
                <>
                  <div className="flex flex-col gap-2">
                    <div className="pb-1 text-sm leading-normal text-gray-600 tablet:w-[70%]">
                      Your organisation
                    </div>

                    <div className="font-semibold text-gray-900">
                      {abnSearchRes?.name}
                    </div>

                    <div className="flex flex-row items-center gap-2">
                      <HiOfficeBuilding className="text-icons h-5 w-5 text-gray-900" />

                      <span className="font-semibold text-gray-900">ABN</span>

                      <span>{abnSearchRes?.ABN}</span>

                      <button
                        className="text-link text-sm"
                        onClick={resetSearch}
                      >
                        Change
                      </button>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>

          <div className="input-form flex w-full flex-col gap-4 tablet:mt-10">
            {
              {
                default: (
                  <T2InviteFormSearchABN
                    registerState={registerState}
                    handleInput={handleInput}
                    search={searchABN}
                    abnRegistered={abnRegistered}
                    abnSearchRes={abnSearchRes}
                    orgSearchRes={orgSearchRes}
                    validateContinue={validateToClaim}
                    canContinue={abnAvailable}
                    abnSearchErr={abnSearchErr}
                    resetSearch={resetSearch}
                    login={loginWithInvite}
                  />
                ),
                login_with_invite: (
                  <T2ConfirmLoginWithInvite
                    inviteVerifyEmail={inviteVerifyEmail}
                    setInviteVerifyEmail={setInviteVerifyEmail}
                    orgSearchRes={orgSearchRes}
                    partnerOrg={partnerOrg}
                    confirmLogin={confirmLoginWithInvite}
                  />
                ),
              }[step]
            }
          </div>

          {/* <div className="input-form flex flex-col gap-[16px]">
          <PartnerT2ClaimForm />
          </div> */}
        </div>
      </div>
    </>
  );
}
